.cotizacion-principal {
    justify-content: space-between;
    align-content: center;
    display: block;
    min-height: 50vh;
    max-height: auto;
    min-width: 50vh;
    max-width: 100vh;
    font-size: 17px;

    &_info {
        font-size: 15px;
        margin-bottom: 10px;
        width: 100%;
        align-items: center;
        justify-content: center;
        text-align: start;
        overflow-wrap: break-word;

        p {
            font-weight: bold;
            margin-bottom: 5px;
        }
    
        span {
            color: rgb(38, 49, 148);
            margin-bottom: 5px;
        }
    }

    // Media Queries for Responsiveness
    @media (max-width: 1024px) {
        min-height: 40vh;
        max-height: 80vh;
        min-width: 40vh;
        max-width: 80vh;

        &_info {
            text-align: center;
            p {
                font-size: 14px;
            }
            span {
                font-size: 12px;
            }
        }
    }

    @media (max-width: 768px) {
        min-height: 30vh;
        max-height: 60vh;
        min-width: 30vh;
        max-width: 60vh;

        &_info {
            text-align: center;
            p {
                font-size: 12px;
            }
            span {
                font-size: 10px;
            }
        }
    }

    @media (max-width: 480px) {
        min-height: 20vh;
        max-height: 40vh;
        min-width: 20vh;
        max-width: 40vh;

        &_info {
            text-align: center;
            p {
                font-size: 10px;
            }
            span {
                font-size: 8px;
            }
        }
    }
}

.form-group {
    font-weight: bold;
    display: flex;
    justify-content: space-between; /* Espacio entre los elementos */
    width: 100%; /* Asegúrate de que ocupa todo el ancho del contenedor */
}

.precio_unitario{
        font-size: 15px;
        font-weight: bold;
}
