@import "~/src/scss/index.scss";

label {
    font-weight: bold;
    margin-bottom: 5px;
}

.gc-form {
    margin-bottom: 10px;

    &__info {
        margin-bottom: 10px;
        width: 100%;
        align-items: center;
        justify-content: center;
        text-align: start;
        font-size: 15px;
        overflow-wrap: break-word;

        p {
            font-weight: bold;
            margin-bottom: 5px;
        }
        label {
            font-weight: bold;
            margin-bottom: 5px;
        }
        span {
            color: rgb(38, 49, 148);
        }
    }

    .table-precio {
        border-collapse: collapse;
        border: 1px solid black;
        font-size: 15px;

        th,
        td {
            border: 1px solid black;
            padding: 1px;
            text-align: left;
        }
    }

    > .ui.container {
        margin-bottom: 20px;
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    span {
        font-weight: bold;
        font-size: 15px;
    }

    // Media Queries for Responsiveness
    @media (max-width: 1024px) {
        &__info {
            p {
                font-size: 14px; // Ajusta el tamaño de fuente para pantallas medianas
            }

            span {
                font-size: 12px; // Ajusta el tamaño de fuente para pantallas medianas
            }
        }

        .table-precio {
            th,
            td {
                padding: 5px; // Aumenta el padding para pantallas medianas
            }
        }

        > .ui.container {
            margin-bottom: 15px;
        }
    }

    @media (max-width: 768px) {
        &__info {
            text-align: center; // Centra el texto en pantallas pequeñas

            p {
                font-size: 12px; // Ajusta el tamaño de fuente para pantallas pequeñas
            }

            span {
                font-size: 10px; // Ajusta el tamaño de fuente para pantallas pequeñas
            }
        }

        .table-precio {
            th,
            td {
                padding: 8px; // Aumenta el padding para pantallas pequeñas
                font-size: 12px; // Ajusta el tamaño de fuente para pantallas pequeñas
            }
        }

        > .ui.container {
            margin-bottom: 10px;
        }
    }

    @media (max-width: 480px) {
        &__info {
            text-align: center;

            p {
                font-size: 10px; // Ajusta el tamaño de fuente para pantallas muy pequeñas
            }

            span {
                font-size: 8px; // Ajusta el tamaño de fuente para pantallas muy pequeñas
            }
        }

        .table-precio {
            th,
            td {
                padding: 4px; // Aumenta el padding para pantallas muy pequeñas
                font-size: 10px; // Ajusta el tamaño de fuente para pantallas muy pequeñas
            }
        }

        > .ui.container {
            margin-bottom: 5px;
        }
    }
}
