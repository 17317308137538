.cotizacion-item {
    display: flex;
    justify-content: space-between;
    align-content: center;
    border-bottom: 1px solid #867f81;
    padding-bottom: 10px;
    margin-top: 30px;
    margin-bottom: 10px;

    &:last-of-type {
        margin-bottom: 0;
        padding-bottom: 0;
        border: 0;
    }

    &__info {
        font-size: 15px;
        display: flex;
        flex-direction: column;
        align-items: center; // Centrar los elementos
        text-align: center;  // Alinear el texto al centro

        &-dxp {
            font-weight: bold;
        }

        &-cliente {
            opacity: 0.6;
        }

        .estatus_Id,
        .estatus_Ope,
        .estatus_GC,
        .estatus_gn {
            font-weight: bold;
            margin-bottom: 2.5px; // Espacio entre los elementos y los otros datos
            margin-left: auto;
        }

        .green {
            background-color: green; // Color verde para finalizados
        }

        .orange {
            background-color: orange; // Color naranja para pendientes
        }
    }

    @media (max-width: 1024px) {
        flex-direction: column;
        align-items: flex-start;

        &__info {
            margin-bottom: 10px;

            &-dxp {
                font-size: 16px;
            }

            &-cliente {
                font-size: 14px;
            }
        }
    }

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;

        &__info {
            margin-bottom: 8px;

            &-dxp {
                font-size: 14px;
            }

            &-cliente {
                font-size: 12px;
            }
        }
    }

    @media (max-width: 480px) {
        flex-direction: column;
        align-items: flex-start;

        &__info {
            margin-bottom: 6px;

            &-dxp {
                font-size: 12px;
            }

            &-cliente {
                font-size: 10px;
            }
        }
    }
}
