.title {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.filters {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.export-button {
    display: flex;
    justify-content: flex-end;
    padding: 10px;

    @media (max-width: 768px) {
        justify-content: center;
        padding-left: 0;
    }
}



.filters-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border: 1px solid #000000;
    padding: 20px;
    border-radius: 5px;
    background-color: #f9f9f9;

    .filter-item {
        flex: 1 1 calc(33.333% - 10px);
        margin: 5px;

        span {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
        }
    }

    @media (max-width: 768px) {
        .filter-item {
            flex: 1 1 calc(50% - 10px);
        }
    }

    @media (max-width: 480px) {
        .filter-item {
            flex: 1 1 100%;
        }
    }
}
