.cotizacion-item {
    display: flex;
    flex-wrap: nowrap; // Cambiado de wrap a nowrap
    justify-content: space-between;
    align-items: center; // Alinear verticalmente los elementos
    border-bottom: 1px solid #847e80;
    margin-bottom: 10px;
    padding-bottom: 10px;
    font-size: 15 px;

    &:nth-last-of-type(2) {
        margin-bottom: 0;
        padding-bottom: 0;
        border: 0;
    }

    &__info {
        display: flex;
        flex-wrap: wrap;
        align-items: center; // Alinear verticalmente los elementos
        text-align: center; // Alinear el texto a la izquierda
        width: 100%;

        &-inyde {
            margin: 0;
            font-weight: normal;
            text-transform: capitalize;
        }

        &-dxp {
            margin: 0;
            font-weight: bold;
            text-transform: uppercase;
        }

        &-label {
            font-weight: bold; // Hace que los encabezados estén en negritas
        }

        &-valor {
            width: 200px;
            word-break: break-all;
            margin-left: 5px; // Un pequeño margen para separar los encabezados de sus valores
        }

        .estatus_Id,
        .estatus_Ope,
        .estatus_GC,
        .estatus_gn{
            font-weight: bold;
            margin-bottom: 2.5px; // Espacio entre los elementos y los otros datos
            margin-left: auto;
        }
    }



    // Media Queries for Responsiveness
    @media (max-width: 1024px) {
        justify-content: center;
        flex-direction: column;

        &__info {
            justify-content: center;
            text-align: center;
        }
    }

    @media (max-width: 768px) {
        padding-bottom: 5px;
        margin-bottom: 5px;
    }

    @media (max-width: 480px) {
        padding-bottom: 3px;
        margin-bottom: 3px;
        border-bottom-width: 0;
    }
}