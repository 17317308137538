@import "~/src/scss/index.scss";

.soporte-form {
    &__documentos {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
        background-color: #a0a0a096;
        margin: 0;
        border: 2px dashed $primary;
        cursor: pointer;
    }
}
