@import "~/src/scss/index.scss";

.auth {
    background-color: #f1f1f1c9;
    min-height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5%; // Ajusta el padding con unidades relativas
    
    .logo {
        padding: 10px;
        height: 130px;
        margin-bottom: -2%;
        padding-right: 195px;
    }

    &_forms {
        width: 30vw; // Ancho del formulario en viewport width
        .ui.menu {
            padding: 0;
            .item {
                width: 50%;
                justify-content: center;
                background-color: #ffffffaf !important;
                border-radius: 11px !important;
                border-top: 2vh solid $border-grey !important; // Ajusta el tamaño del borde en viewport height
                font-size: 2vh; // Tamaño de fuente en viewport height
                &.active {
                    font-weight: normal;
                    border-radius: 5px !important;
                    border-top-color: $primary !important;
                }
            }
        }
    }

    .crsl {
        width: 50vw; // Ancho del carousel en viewport width
        .carousel .slide {
            background: none; // Elimina el fondo predeterminado del carrusel
        }
        .carousel-image {
            width: 100%;
            height: auto; // Ajuste para mantener la proporción de la imagen
            max-height: 80vh; // Ajuste máximo de altura para imágenes
            object-fit: contain; // Ajuste para que la imagen contenga dentro del div
        }
    }

    .sidebar {
        flex: 1;
        padding-top: 10vh; // Padding superior en viewport height
    }

    .content {
        flex: 1;
        width: 25vw; // Ancho en viewport width
        text-align: center;
    }

    // Media query para ajustes adicionales en tamaños de pantalla más pequeños
    @media (max-width: 1024px) {
        .crsl {
            width: 45vw; // Ajuste de ancho del carousel para pantallas medianas
        }
        &_forms {
            width: 35vw; // Ajuste de ancho del formulario para pantallas medianas
        }
    }

    @media (max-width: 768px) {
        flex-direction: column; // Cambia la dirección del flex para dispositivos pequeños
        padding: 0 2%; // Reducir el padding en pantallas pequeñas
        align-items: flex-start; // Alinear elementos al inicio para dispositivos pequeños

        .logo {
            margin-bottom: 5vh; // Añade margen inferior en viewport height
            padding-right: 0; // Elimina padding right en pantallas pequeñas
            height: auto; // Ajustar altura de logo automáticamente
            width: 100%; // Ancho completo del logo en pantallas pequeñas
            text-align: center; // Centrar el logo en pantallas pequeñas
        }

        .content, .sidebar, .crsl, &_forms {
            width: 90vw; // Ancho en viewport width para dispositivos pequeños
            margin: auto; // Centra los elementos
            padding: 2% 0; // Ajusta el padding en pantallas pequeñas
        }

        .content {
            order: 1; // Ordena el contenido para pantallas pequeñas
        }

        .sidebar {
            order: 2; // Ordena la barra lateral para pantallas pequeñas
            padding-top: 5vh; // Ajustar padding superior en pantallas pequeñas
        }

        .crsl {
            order: 3; // Ordena el carousel para pantallas pequeñas
            width: 100%; // Ancho completo del carousel en pantallas pequeñas
        }

        &_forms {
            order: 4; // Ordena los formularios para pantallas pequeñas
        }
    }
}
