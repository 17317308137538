$primary: #0070b7;
$primary-hover: #003d63;

//Text
$text-light: #ffff;

//background
$background-dark: #857f81;
$background-grey: #d2d2ca;
$background-dark-web: #16212b;

//Border
$border-grey: #808080;

//Status
$succes: #84b84c;
$error: #9f3a38;

