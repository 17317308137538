@import "~/src/scss/index.scss";

.ui.menu.admin-menu {
    width: 100%;
    padding: 0;

    > .item {
        font-size: 15px;
        display: flex;
        align-items: center;
        color: #ffffff;
        padding: 20px 50px !important;
        margin: 0 !important;
        border-radius: 0 !important;

        &:hover,
        &.active,
        &.active:hover {
            color: #0081c1;
            background-color: #ffffff;
        }

        > i {
            margin: 0 !important;
            margin-right: 10px !important;
        }
    }

    .dropdown {
        width: 100%; 
        .text {
            font-weight: bold;
        }
        .dropdown.icon {
            float: right; 
            margin-left: 10px !important;
        }
    }
    .menu {
        background-color: #f9f9f9;
        border: 1px solid #ddd;
        box-shadow: 0 2px 10px rgba(0,0,0,0.1); 
        margin-top: 4px;

        .item {
            color: #f4f9fd; 

            &:hover,
            &.active,
            &.active:hover {
                color: #ffffff;
                background-color: #0081c1;
            }
        }
    }

    // Media Queries for Responsiveness
    @media (max-width: 1024px) {
        > .item {
            font-size: 14px;
            padding: 15px 40px !important;
        }
    }

    @media (max-width: 768px) {
        > .item {
            font-size: 13px;
            padding: 10px 30px !important;
        }
        .dropdown {
            .dropdown.icon {
                margin-left: 5px !important;
            }
        }
    }

    @media (max-width: 480px) {
        > .item {
            font-size: 12px;
            padding: 8px 20px !important;
            flex-direction: column;
            align-items: flex-start;
        }
        .dropdown {
            .text {
                font-size: 14px;
            }
            .dropdown.icon {
                float: none;
                margin: 5px 0 0 0 !important;
            }
        }
        .menu {
            .item {
                font-size: 12px;
                padding: 10px 15px !important;
            }
        }
    }
}
