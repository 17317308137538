@import "~/src/scss/index.scss";

.menu-item{
    display: flex;
    justify-content: space-between;
    align-content: center;
    border-bottom: 1px solid #ffff;
    margin-bottom: 10px;
    padding-bottom: 10px;

    &:last-of-type{
        margin-bottom: 0;
        padding-bottom: 0;
        border: 0;
    }

    &__info{
        display: flex;
        flex-direction: column;
        &-title{
            font-weight: bold;
        }
        &-path{
            opacity: 0.6;
        }
    }
}