.imagen-perfil {
    justify-content: center;
    align-items: center;
    font-size: 80px; 
    text-align: center;
}


.imagen-perfil h2 {
    margin-top: 30px;
    font-size: 30px;
    text-align: left;
}

.imagen-perfil h1 {
    margin-top: 30px;
    font-size: 30px;
}

.datos-personales{
    font-size: 20px;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    font-family: Arial, sans-serif;
  }

  .profile-container {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
  }

  .profile-container h1 {
    text-align: center;
  }




