.inyde-form {
    padding: 8px;

    // Media Queries for Responsiveness
    @media (max-width: 768px) {
        padding: 6px; // Reducir el padding en pantallas pequeñas
    }

    @media (max-width: 480px) {
        padding: 4px; // Reducir aún más el padding en pantallas muy pequeñas
    }
}

.inyde-form {
    margin-bottom: 10px;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: start;
    overflow-wrap: break-word;

    p {
        font-weight: bold;
        margin-bottom: 5px;
        font-size: 15px;
    }

    span {
        color: rgb(38, 49, 148);
        
    }
    
}
.inyde-form__label{
    font-size: 15px;
}

.custom-button {
    padding: 8px;

    // Media Queries for Responsiveness
    @media (max-width: 768px) {
        padding: 6px; // Reducir el padding en pantallas pequeñas
    }

    @media (max-width: 480px) {
        padding: 4px; // Reducir aún más el padding en pantallas muy pequeñas
    }
}

.custom-checkbox {
    padding-bottom: 8px;

    // Media Queries for Responsiveness
    @media (max-width: 768px) {
        padding-bottom: 6px; // Reducir el padding en pantallas pequeñas
    }

    @media (max-width: 480px) {
        padding-bottom: 4px; // Reducir aún más el padding en pantallas muy pequeñas
    }
}
